import type { DetailedPlace } from '~/modules/location/type';
import {
    getFormattedPlaceName,
    getPlaceTypeName,
} from '~/modules/location/util';

export interface PlaceFinderDropdownItem<T> {
    header: string;
    value: T;
    selected: boolean;
    prefix: string;
}

export const getPlaceDropdownOptions = (
    places: DetailedPlace[],
    selectedIndex = -1
): Array<PlaceFinderDropdownItem<DetailedPlace>> => {
    const uniquePlaces: PlaceFinderDropdownItem<DetailedPlace>[] = [];

    places.forEach((place, index) => {
        const header = getFormattedPlaceName(place);
        if (header !== '') {
            const existed = uniquePlaces.some(
                (item) => item.header === header && item.value === place
            );
            if (!existed || index === selectedIndex) {
                uniquePlaces.push({
                    header,
                    value: place,
                    selected: index === selectedIndex,
                    prefix: getPlaceTypeName(place, true),
                });
            }
        }
    });

    return uniquePlaces;
};
