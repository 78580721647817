import { byCountryFactory } from '@archipro-website/localisation';
import { SupportedCountry } from 'generated/graphql'; // this is the one used in graphql
import type { SupportedCountry as LocalisationCountry } from '@archipro-website/localisation';

export const getValueByCountryCode = <T = string>(
    countryRecords: Record<SupportedCountry, T>,
    defaultValue: T,
    country?: string
): T => {
    const countryCode = getCountryCodeByName(country);
    if (!countryCode) {
        return defaultValue;
    }
    const byCountry = byCountryFactory(countryCode as LocalisationCountry);
    return byCountry(countryRecords);
};

export const getStateNameByCountry = (country?: string): string => {
    return getValueByCountryCode(
        {
            AU: 'State',
            NZ: 'Island',
        },
        '',
        country
    );
};

export const getRegionNameByCountry = (country?: string): string => {
    return getValueByCountryCode(
        {
            AU: 'State',
            NZ: 'Region',
        },
        '',
        country
    );
};

export const getFullCountryName = (country?: string): string => {
    return getValueByCountryCode(
        {
            AU: 'Australia',
            NZ: 'New Zealand',
        },
        '',
        country
    );
};

export const getCountryServiceableAreaID = (country?: string): number => {
    return getValueByCountryCode(
        {
            AU: 10001,
            NZ: 1,
        },
        0,
        country
    );
};

export const getCountryCodeByName = (
    country?: string
): SupportedCountry | null => {
    const upperCountry = country?.toUpperCase();
    if (upperCountry === 'AUSTRALIA' || upperCountry === 'AU') {
        return SupportedCountry.Au;
    }
    if (upperCountry === 'NEW ZEALAND' || upperCountry === 'NZ') {
        return SupportedCountry.Nz;
    }
    return null;
};
