import type { LoaderFunctionArgs } from '@remix-run/node';
import { findPlaceByKeyword } from '~/modules/location/api/find-place-by-keyword.server';

// doc: https://archipro.atlassian.net/wiki/spaces/AD/pages/1777139713/Place+Finder
// gql: packages/web/app/modules/location/graphql/query/find-place.graphql

export const FIND_PLACE_REMIX_API = '/remix-api/location/find-place-by-keyword';

export const loader = async (args: LoaderFunctionArgs) => {
    return await findPlaceByKeyword(args);
};
