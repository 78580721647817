import { useFetcher } from '@remix-run/react';
import { useDebounceFn } from 'ahooks';
import type { SupportedCountry } from 'generated/graphql';
import { FindPlaceApiProviderEnum, FindPlaceTypeEnum } from 'generated/graphql';
import { useCallback, useEffect, useState } from 'react';
import { getPlaceDropdownOptions } from '~/modules/location/util';
import type { DetailedPlace } from '~/modules/location/type';
import { FIND_PLACE_REMIX_API } from '~/routes/remix-api.location.find-place-by-keyword';
import type { loader } from '~/routes/remix-api.location.find-place-by-keyword';
import { useCountryContext } from '@archipro-website/localisation';

interface UsePlaceFinderProps {
    limit?: number;
    keyword?: string;
    type?: FindPlaceTypeEnum;
    country?: SupportedCountry;
    apiProvider?: FindPlaceApiProviderEnum;
    apiVersion?: string;
}

// doc: https://archipro.atlassian.net/wiki/spaces/AD/pages/1777139713/Place+Finder
// gql: packages/web/app/modules/location/graphql/query/find-place.graphql
// api: /remix-api/location/find-place-by-keyword

export const usePlaceFinder = ({
    limit = 10,
    keyword = '',
    type = FindPlaceTypeEnum.SuburbMixed,
    country,
    apiProvider = FindPlaceApiProviderEnum.Mapbox,
    apiVersion = '',
}: UsePlaceFinderProps) => {
    const fetcher = useFetcher<typeof loader>();
    const [places, setPlaces] = useState<DetailedPlace[]>([]);
    const { code: countryCode } = useCountryContext();
    const searchCountry = country ?? countryCode;

    const updateKeyword = useCallback(
        async (newKeyword?: string) => {
            if (!newKeyword || newKeyword.trim().length < 2) {
                return;
            }
            if (fetcher.state === 'idle') {
                const formData = new FormData();
                formData.append('keyword', newKeyword.trim());
                formData.append('type', type.toString());
                formData.append('limit', limit.toString());
                formData.append('country', searchCountry.toString());
                formData.append('apiProvider', apiProvider.toString());
                formData.append('apiVersion', apiVersion);

                fetcher.submit(formData, {
                    method: 'get',
                    action: FIND_PLACE_REMIX_API,
                });
            }
        },
        [apiProvider, apiVersion, searchCountry, fetcher, limit, type]
    );

    const { run: changeKeyword } = useDebounceFn(updateKeyword, {
        wait: 500,
    });

    useEffect(() => {
        setPlaces(fetcher?.data ?? []);
    }, [fetcher.data]);

    useEffect(() => {
        if (keyword.trim().length > 1) {
            changeKeyword(keyword);
        }
    }, [changeKeyword, keyword]);

    return {
        data: places,
        loading: fetcher.state === 'loading' || fetcher.state === 'submitting',
        changeKeyword,
        dropdownOptions: getPlaceDropdownOptions(places),
    };
};
