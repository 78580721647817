import type { DetailedPlace } from '~/modules/location/type';
import { FindPlaceTypeEnum } from 'generated/graphql';

export const getFormattedPlaceName = (place: DetailedPlace) => {
    const newRegion = place.suburb ? '' : place.region;
    const newCity = place.district ? place.district : place.city;
    let placeFullName = [place.suburb, newCity, newRegion]
        .filter(Boolean)
        .join(', ');
    if (!placeFullName) {
        placeFullName = place.fullAddress || '';
    }
    if (place.placeType === FindPlaceTypeEnum.District) {
        placeFullName = place.fullAddress || '';
    }
    if (place.region && !placeFullName.includes(place.region)) {
        placeFullName += `, ${place.region}`;
    }
    if (!place.region && place.state && !placeFullName.includes(place.state)) {
        placeFullName += `, ${place.state}`;
    }
    if (!place.latitude) {
        placeFullName += ' *';
    }
    return placeFullName;
};
