import type { DetailedPlace } from '~/modules/location/type';
import { FindPlaceTypeEnum } from 'generated/graphql';
import {
    getRegionNameByCountry,
    getStateNameByCountry,
} from '~/modules/location/util';

export const getPlaceTypeName = (
    place: DetailedPlace,
    withComma = false
): string => {
    const prefix = place.suburb
        ? 'Suburb'
        : place.placeType === FindPlaceTypeEnum.District
        ? 'District'
        : place.city
        ? 'City'
        : place.region
        ? getRegionNameByCountry(place.country)
        : place.fullAddress.includes('Island')
        ? 'Island'
        : place.state
        ? getStateNameByCountry(place.country)
        : place.country
        ? 'Country'
        : 'Other';

    return withComma ? `${prefix},` : prefix;
};
